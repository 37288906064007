import * as React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import { ApmRoutes } from '@elastic/apm-rum-react';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ApplicationProcessPage from "./views/ApplicationProcessPage";
import CourseOptionPage from "./views/CourseOptionPage";
import Footer from "./components/footer/Footer";
import Navbar from "./components/header/Navbar";
import NotFoundPage from "./views/NotFoundPage";
import SearchPage from "./views/SearchPage";



export default function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "100vh" }}>
        <BrowserRouter>
          <Navbar />
          <ApmRoutes>

            <Route path="/search" element={<SearchPage />} />
            <Route path="/favourites" element={<SearchPage fav={true} />} />
            <Route path="/application-process" >
              <Route index element={<ApplicationProcessPage />} />
              <Route path="abroad" element={<CourseOptionPage variant="abroad" />} />
              <Route path="online" element={<CourseOptionPage variant="online" />} />
              <Route path="blended" element={<CourseOptionPage variant="blended" />} />
            </Route>
            <Route index path="/" element={<SearchPage />} />
            <Route path="*" element={<NotFoundPage />} />

          </ApmRoutes>
          <Footer />
        </BrowserRouter>
      </div>
    </LocalizationProvider>
  );
}
