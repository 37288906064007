import React from "react";
import ReactDOM from "react-dom";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { apm } from '@elastic/apm-rum';

import "./htmlStyles.css";
import App from "./App";
import { config } from './config';
import { enlightTheme } from "./theme";






ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={enlightTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

apm.init(config.apmConf);
