import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link as RouterLink } from 'react-router-dom';

import ExternalLink from "../ExternalLink";
import WrapperComponent from "../WrapperComponent";
import enlight_logo from "../../assets/images/newLogos/enlight_logo.png";

const navbarStyles = {
  height: "6rem",
  backgroundColor: "#fff",
  padding: {xs: "1rem", md: "0.5rem 4rem"},
  boxShadow: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const Navbar: React.FC = () => {
  return (
    <AppBar
      position="sticky"
      sx={ navbarStyles }
    >
      <WrapperComponent>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",}}>
          <Button
            component={RouterLink}
            to="/"
          >
            <img
              width="134.59px"
              height="48px"
              src={enlight_logo}
              alt="ENLIGHT logo"
            />
          </Button>
          <ExternalLink
            href="https://enlight-eu.org/index.php/students/courses"
          >
            <Button
              color="enlightTeal"
              endIcon={<OpenInNewIcon />}
              sx={{fontSize: "1.2rem"}}
            >
              ENLIGHT Joint Courses
            </Button>
          </ExternalLink>
        </Box>
      </WrapperComponent>
    </AppBar>
  );
};

export default Navbar;
