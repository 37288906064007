import { DateTime } from "luxon";
import { FieldOptions } from "../components/fields";



export const handleDate = (date: string) => {
  const start = new Date(date);
  const startDateTime = DateTime.fromJSDate(start);

  return `${startDateTime.toISODate()}`;
};

/** Find an object with key and label from array based on key and returns a label from that found object */
export const getLabel = ( list: { name: string, value: string }[], val:string) => {
  const foundItem = list?.find((i) => i.value === val);
  return foundItem && foundItem.name;
};
