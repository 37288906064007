import * as React from 'react';

import { Box, FormControl, Slider as MuiSlider } from '@mui/material';
import { SliderProps, SliderThumb } from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { FieldProps } from '.';


interface CustomThumbProps extends React.HTMLAttributes<unknown> {  }
function CustomThumb(props: CustomThumbProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {/* <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" /> */}
    </SliderThumb>
  );
}

const EnlightSlider = styled(MuiSlider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  "& .MuiSlider-track": {
    height: "0.75rem",
    color: theme.palette.enlightTeal.lighter,
    background: "#F2F7FA",
    border: "1px solid rgba(23, 72, 106, .3)",
    borderRadius: "80px"
  },
  "& .MuiSlider-rail": {
    height: "0.75rem",
    color: theme.palette.enlightTeal.lighter,
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid rgba(23, 72, 106, .3)",
  },
  "& .MuiSlider-thumb": {
    height: "1.5rem",
    width: "1.5rem",
    backgroundColor: "#fff",
    borderRadius: "0.25rem",
  },
}));


interface SliderP extends SliderProps {
  value: number[];
}

export const Slider: React.FC<FieldProps<number[]> & SliderP> = ( props ) => {

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    const currentThumb = activeThumb ? "ectsMax" : "ectsMin";
    const currentThumbValue = typeof newValue === 'number' ? newValue : newValue[activeThumb];
    if ( props.cb ) {
      //@ts-ignore
      props.cb( currentThumb, currentThumbValue );
    }
  };

  return (
    <FormControl fullWidth>
      <Stack mx={2} alignItems="start">

        <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
          <Typography variant="caption" gutterBottom sx={{ml: -1.5, color: "enlightTeal.main"}} >
              ECTs
          </Typography>
          <Typography variant="caption" gutterBottom sx={{mr: -1.5, color: "enlightTeal.main"}} >
            1-30
          </Typography>
        </Box>

        <EnlightSlider
          name="ectsValue"
          components={{ Thumb: CustomThumb }}
          value={props.value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaLabel={() => "ECTs range"}
          min={ 0 }
          max={ 30 }
        />

      </Stack>
    </FormControl>
  );
};
