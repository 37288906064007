import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import WrapperComponent from "./WrapperComponent";

interface SectionHeadProps  {
  text?: string,
  description?: string,
  align: "right" | "left" | "inherit" | "center" | "justify" | undefined,
}

const SectionHead: React.FC<SectionHeadProps> = (props) => {
  return (
    <Box sx={{
      backgroundColor: "rgba(235, 248, 252, 1)",
      pt: 2,
      pb: 4,
      mb: 4,
      px: {xs: "1rem", md: "4rem"}
    }}>
      <WrapperComponent sx={{margin: "auto"}}>
        {props.text && <Typography variant="h4" component={'h1'} fontWeight={700} align={props.align} mb={1} color="enlightTeal.main">
          {props.text}
        </Typography>}
        {props.description &&
          <Typography variant="body1" color="enlightTeal.main" sx={{ whiteSpace: 'pre-line'}}>
            {props.description}
          </Typography>
        }
        {props.children && <>{props.children}</>}
      </WrapperComponent>
    </Box>
  );
};

export default SectionHead;