import Box from "@mui/material/Box";
import { styled  } from "@mui/system";

import ExternalLink from "../ExternalLink";

import Bordeaux from "../../assets/images/universityLogos/BordeauxLogoWhite.png";
import Bratislave from "../../assets/images/universityLogos/BratislavaLogoWhite.png";
import Galway from "../../assets/images/universityLogos/GalwayLogoWhite.png";
import Ghent from "../../assets/images/universityLogos/GhentLogoWhite.png";
import Groningen from "../../assets/images/universityLogos/GroningenLogoWhite.png";
import Göttingen from "../../assets/images/universityLogos/GottingenLogoWhite.png";
import PaisVasco from "../../assets/images/universityLogos/BasqueLogoWhite.png";
import Tartu from "../../assets/images/universityLogos/TartuLogo.png";
import Uppsala from "../../assets/images/universityLogos/UppsalaLogoWhite.png";


const PartnerLogo = styled("img")({
  maxHeight: "4.5rem",
  maxWidth: "8rem",
  fill: "white",
  "&:hover": {
    filter: "grayscale(0)",
    opacity: 1
  }
});

const logos = [
  { src: PaisVasco, url: "https://www.ehu.eus/en/en-home" },
  { src: Bordeaux, url: "https://www.u-bordeaux.com/" },
  { src: Bratislave, url: "https://uniba.sk/en/migracia/comenius-university-in-bratislava/" },
  { src: Groningen, url: "https://www.rug.nl/" },
  { src: Galway, url: "https://www.nuigalway.ie/" },
  { src: Göttingen, url: "https://www.uni-goettingen.de/en/1.html" },
  { src: Ghent, url: "https://www.ugent.be/en" },
  { src: Tartu, url: "https://ut.ee/en" },
  { src: Uppsala, url: "https://www.uu.se/en" },
];

const logosContainerStyles = {
  display: "flex",
  flexDirection: "row",
  columnGap: {xs: "1rem", lg: "5rem", xl: "1rem"},
  rowGap: "1rem",
  width: "100%",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: {xs: "space-around", /*lg: "center",*/ xl: "space-between"}
};

const Logos: React.FC = () => {
  return (
    <Box component="div" sx={logosContainerStyles}>
      {
        logos.map((logo, index) => (
          <ExternalLink
            key={index}
            href={logo.url}
            sx={{flex: {xs: "1 1 50%", sm: "1 1 30%", md: "1 1 20%", lg: "auto"}}}
          >
            <PartnerLogo src={logo.src} alt="" />
          </ExternalLink>
        ))
      }
    </Box>
  );
};

export default Logos;
