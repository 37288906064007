import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled  } from "@mui/system";

import { Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import ExternalLink from "../ExternalLink";


const StyledLink = styled(ExternalLink)(({theme}) => ({
  color: theme.palette.secondary.light,
  "&:hover": {
    color: theme.palette.secondary.main
  }
}));

const testValues = () => {
  /* console.info(
    'getBackendURL: ', config.baseUrl, '\n',
    'node_env: ', process.env.NODE_ENV, '\n',
    'react_env: ', process.env.REACT_APP_ENVIRONMENT,
    'react_test: ', process.env.REACT_APP_TEST,
  ); */
};

const Links: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Grid container component="div" sx={{ color: "secondary.light"}} spacing={2} >

      <Grid item xs={12} md={3} sx={{textAlign: "left", alignSelf: "center"}}>
        <Typography variant="subtitle1" onClick={testValues}>
          © {currentYear} ENLIGHT
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}  sx={{ display: "flex", fontSize: "0.75rem", gap: 1, justifyContent: {xs: "left", md: "center"} }}>
        <StyledLink href="https://enlight-eu.org/contact/privacy-policy">
          Privacy Policy
        </StyledLink>
      </Grid>

      <Grid item xs={12} md={3}   sx={{display: "flex", gap: "0.5rem", justifyContent: {xs: "left", md: "right"}, mt: {xs: 2, md: 0}}}>
        <ExternalLink href="https://www.instagram.com/enlighteu/">
          <IconButton size="small" sx={{ backgroundColor: "whiteWithOpacity.main"}}>
            <Instagram  sx={{color: "white"}} fontSize="small" />
          </IconButton>
        </ExternalLink>
        <ExternalLink href="https://twitter.com/enlight_eu">
          <IconButton size="small" sx={{ backgroundColor: "whiteWithOpacity.main" }}>
            <Twitter  sx={{color: "white"}} fontSize="small" />
          </IconButton>
        </ExternalLink>
        <ExternalLink href="https://www.linkedin.com/company/enlight-europeanuniversity/?viewAsMember=true">
          <IconButton size="small" sx={{ backgroundColor: "whiteWithOpacity.main" }}>
            <LinkedIn  sx={{color: "white"}} fontSize="small" />
          </IconButton>
        </ExternalLink>
        <ExternalLink href="https://www.youtube.com/c/ENLIGHTEU">
          <IconButton size="small" sx={{ backgroundColor: "whiteWithOpacity.main" }}>
            <YouTube  sx={{color: "white"}} fontSize="small" />
          </IconButton>
        </ExternalLink>
      </Grid>

    </Grid>
  );
};

export default Links;
