import * as React from 'react';

import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { FieldOptions, FieldProps } from '.';
import { getLabel } from '../../helpers';
import { styles } from './styles';


function makeSelectedBold(name: string, currentValue: readonly string[], theme: Theme) {
  return {
    fontWeight:
      currentValue.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultiSelectProps {
  label: string;
  options: { name: string, value: string }[] | undefined;
}

export const MultiSelect: React.FC<FieldProps<string[]> & SelectProps & MultiSelectProps> = (props) => {
  const biggerXsScreenSize = useMediaQuery('(min-width: 500px) and (max-width: 599px)', { noSsr: true });

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const { value } = event.target;
    if ( props.cb ) {
      props.cb(
        props.name,
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const handleDelete = (e: React.MouseEvent, value: string) =>  {
    const newValueWithout = props.value.filter((i) => i !== value);
    if ( props.cb ) {
      props.cb( props.name, newValueWithout );
    }
  };

  const renderValue = (selected: typeof props.value) => {
    const remainingSelectedItems = Math.max(selected.length - (biggerXsScreenSize ? 2 : 1), 0);

    return (
      <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap', gap: 0.5, alignItems: "center", overflow:'hidden'}}>
        {
        selected.length > 0
          ? <>
              <Chip
                key={selected[0]}
                label={getLabel(props?.options || [], selected[0])}
                variant="outlined"
                sx={styles.selectChip}
                deleteIcon={<ClearOutlinedIcon sx={{color: "enlightTeal.main"}} onMouseDown={(e) => e.stopPropagation()} />}
                onDelete={(e) => handleDelete(e, selected[0])}
                size="small"
              />
              {
                (selected.length > 1 && (biggerXsScreenSize)) &&
                  <Chip
                    key={selected[1]}
                    label={getLabel(props?.options || [], selected[1])}
                    variant="outlined"
                    sx={styles.selectChip}
                    deleteIcon={<ClearOutlinedIcon sx={{color: "enlightTeal.main"}} onMouseDown={(e) => e.stopPropagation()} />}
                    onDelete={(e) => handleDelete(e, selected[1])}
                    size="small"
                  />
              }
              {(remainingSelectedItems > 0) && <Typography variant="subtitle1">+ {remainingSelectedItems}</Typography>}
          </>
          : "Any"
        }
      </Box>
    );
  };

  return (
    <FormControl fullWidth>

      <InputLabel id={"multiple-" + props.name} sx={styles.selectLabel}>
        { props.label }
      </InputLabel>

      <Select
        multiple
        displayEmpty={true}
        value={props.value}
        placeholder={'select'}
        onChange={handleChange}
        input={
          <OutlinedInput
            notched={false}
          /> }
        renderValue={ renderValue }
        sx={ styles.select }
      >
        {props.options?.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={(theme) => ({
              fontWeight: makeSelectedBold(item.value, props.value, theme)
            })}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
