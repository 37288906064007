import * as React from 'react';

import Container from '@mui/material/Container';
import NewContentWrapper from '../components/NewContentWrapper';



const containerStyles = {
  mt: {xs: 0, md: 3},
  width: "100%",
};

interface SearchPageProps {
  fav?: boolean
}

const SearchPage: React.FC<SearchPageProps> = (props) => {

  return (
    <Container
      component="main"
      maxWidth="xl"
      disableGutters
      sx={containerStyles}
    >
      <NewContentWrapper fav={props.fav} />
    </Container>
  );
};

export default SearchPage;
