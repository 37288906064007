import * as React from 'react';

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Api from '@mui/icons-material/Api';
import StarIcon from "@mui/icons-material/Star";

import ApiService from "../../config/api";
import { Link } from 'react-router-dom';


const isDevelopment = (process.env.REACT_APP_ENVIRONMENT ?? process.env.NODE_ENV) === "development";

interface TableTitleProps {
  showFavorite: boolean;
  setShowFavorites: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Toolbar containing title of the table and button to toggle between favourites and all courses view
 */
export function TableTitle(props:TableTitleProps) {
  return (
    <Toolbar sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
      backgroundColor: "white",
      color: "rgba(23, 72, 106, 1)",
      justifyContent: { xs: "flex-end", md: "space-between"},
    }}>

        <Typography variant="h4" component={'h1'} fontWeight={700} sx={{ display: {xs: "none", md: "block" }}}>
          {
            props.showFavorite
              ? "Favourites"
              : "Best place to search and find courses"
          }
        </Typography>

        <ButtonGroup
          size='small'
          variant="text"
          aria-label="outlined button group"
          sx={{
            justifyContent: "end",
            alignItems: "end",
            color: "rgba(23, 72, 106, 0.5)",
          }}
        >
          <Button
            component={Link}
            to="/application-process"
            sx={{color: "enlightTeal.main", fontWeight: 600, fontSize: "1rem", px: 1}}
          >See about application process</Button>
          <Button
            component={Link}
            to={props.showFavorite ? "/search" : "/favourites"}
            onClick={() => props.setShowFavorites(!props.showFavorite)}
            sx={{color: "enlightTeal.main", fontWeight: 600, fontSize: "1rem", px: 1}}
          >
            <StarIcon sx={{ mr:1 }} />
            { "View " + (props.showFavorite ? "all courses" : "favorites") }
          </Button>
          {/* {
            isDevelopment && (
              <Button onClick={()=> ApiService.importer()}>
                <Api sx={{ mr:1 }} /> POST import
              </Button>
            )
          } */}
        </ButtonGroup>

    </Toolbar>
  );
};
