import { Container, Modal, Stack, Typography } from "@mui/material";
import React from "react";

import ExternalLink from "../ExternalLink";
import { Offering } from "../../@types/courseTypes";


type ModalProps = JSX.IntrinsicAttributes & {
  isOpen: boolean;
  onClose: (val: boolean) => void | React.SetStateAction<boolean>;
  organization: Offering["organization"];
};

/** Show information about the university */
const OrganizationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  organization,
}) => {
  const handleClose = () => {
    onClose(false);
  };

  const address = typeof organization !== 'string' && organization?.addresses
    ? organization.addresses[0]
    : null;

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Container
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          fontSize: 14,
          width: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack>
          {typeof organization !== 'string' && organization && (
            <>
              <Typography variant="h4" sx={{ mb: "1rem" }}>
                {organization.name[0].value}
              </Typography>
              <Typography>
                <strong>Type of organization: </strong>
                {organization.organizationType as string}
              </Typography>
            </>
          )}
          {address && (
            <Typography>
              <strong>Address: </strong>
              {`${address.street} ${address.streetNumber}, ${address.city}, ${address.countryCode}, ${address.postalCode}`}
            </Typography>
          )}
          {address?.geolocation && (
            <Typography>
              <strong>Geolocation: </strong>
              {`${address.geolocation.latitude} (latitude), ${address.geolocation.longitude} (longitude)`}
            </Typography>
          )}
          {typeof organization !== 'string' && organization?.link && (
            <>
              <Typography sx={{display: "inline-block"}}>
                <strong>Website: </strong>
              </Typography>
              <ExternalLink href={organization.link} sx={{margin: "1rem", fontSize: "0.875rem"}}>
                {`${organization.link}`}
              </ExternalLink>
            </>
          )}
          {typeof organization !== 'string' && organization?.description && organization.description.length && (
            organization.description.map((desc, i) =>
              <Typography key={i}>{desc.value}</Typography>
          ))}
        </Stack>
      </Container>
    </Modal>
  );
};

export default OrganizationModal;
