import React from "react";

import { Offering } from "../@types/courseTypes";


interface FavoriteHookProps {
  favItems: Offering[];
  addFavItem(item: Offering | Offering[]): void;
  removeFavItem(id: Offering['offeringId']): void;
};
const initialValue = localStorage.getItem("favorites") ?? "[]";

/**
 * A custom hook for managing adding courses to favourite and removing courses from favourites.
 * Uses browser's local storage for storing favourited courses.
 */
export function useFavorites(): FavoriteHookProps {
  const [favoriteList, setFavoriteList] = React.useState<Offering[]>(
    JSON.parse( initialValue )
  );

  const getLocalStorage = (): null | Offering[] => {
    const cashedFavorites = localStorage.getItem("favorites");
    if ( !cashedFavorites ) {
      return null;
    }
    return JSON.parse(cashedFavorites);
  };

  const addFavItem = (course: Offering | Offering[] ) => {
    if ( Array.isArray(course) ) {
      setFavoriteList( course );
    } else {
      const newList = favoriteList;
      newList.push(course);
      setFavoriteList( newList );
    }
  };

  const removeFavItem = (id: Offering['offeringId']) => {
    const filteredList = favoriteList.filter( (item) => item.offeringId !== id );
    setFavoriteList(filteredList);
  };

  // on hook mount updater
  React.useEffect(() => {
    const favoriteList = getLocalStorage();
    if ( favoriteList ) {
      setFavoriteList( favoriteList );
    }
  }, []);

  // update local storage item every time when favorites state changes.
  React.useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favoriteList));
  }, [favoriteList]);

  return { favItems: favoriteList, addFavItem, removeFavItem };
};
