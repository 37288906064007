import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import HomeButton from '../components/HomeButton';


export default function NotFoundPage() {
  return (
    <Container>
      <Typography variant="h2" component={'h1'} color="enlightTeal.main">Page not found</Typography>
      <HomeButton />
    </Container>
  );
}
