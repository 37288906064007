const env: ApplicationENV = process.env;

const apiUrl = env.REACT_APP_BACKEND;

const config = {
  appEnv: env.REACT_APP_ENV,
  nodeEnv: env.NODE_ENV,
  baseUrl: apiUrl,
  apmConf: {
      serverUrl: env.REACT_APP_ELASTIC_URL,
      serviceName: env.REACT_APP_ELASTIC_SERVICE_NAME,
      environment: env.REACT_APP_ENV,
  }
};

export { config };
