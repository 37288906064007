import * as React from 'react';
import Button from '@mui/material/Button';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { UniversitiesOptions } from '../@types/filterTypes';

interface UniSelectionButtonProps {
  text: string,
  cb: (event: React.MouseEvent<HTMLLIElement>) => void;
  universities: UniversitiesOptions[];
}

const UniSelectionButton: React.FC<UniSelectionButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    props.cb(event);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        fullWidth
        color="enlightTeal"
        sx={{ p: 2 }}
        endIcon={<ExpandCircleDownIcon sx={{ color: "white" }} />}
      >
        <Typography color="white" fontWeight={700}>
          {props.text}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: "center"}}
        transformOrigin={{vertical: 'top', horizontal: "center"}}
        sx={{"& .MuiMenu-paper": { minWidth: { xs: '85%', md: '45%' } }}}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.universities.map((uni, i) => (
          <MenuItem key={i} onClick={handleClose}>{uni.label}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UniSelectionButton;