import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';


const HomeButton: React.FC = () => {
  return (
    <Button
      component={Link}
      variant="text"
      to={"/"}
      color="enlightTeal"
      size="small"
      sx={{ p: "0.5rem", m: "0  0 0 -0.5rem", color: "enlightTeal.main", fontWeight: 700, align: "right", fontSize: "1rem" }}
      startIcon={<ArrowBackIcon />}
    >
      HOME
    </Button>
  );
};

export default HomeButton;
