import { FilterRequest, FilterResponse, Filters, Payload } from "../@types/apiTypes";
import { config } from ".";


const apiEndpoint = config.baseUrl;

/**
 * Method for handling requests to API endpoint - creating request and fetching data
 */
function handler<T>({ path, method, payload }: FilterRequest): Promise<T> {
  // create new header
  const headers = new Headers();
  headers.append( "Content-Type", "application/json" );

  const body: RequestInit['body'] = payload && JSON.stringify(payload);

  return fetch( apiEndpoint + path, { headers, method, body })
  .then( (response) => {
    /* A fulfilled callback answer */
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  })
  .catch( (error: Error) => {
    throw error;// <-- rethrow the error so consumer can still catch it.
  });
};

const fetchFilter = (payload: Payload) => handler<FilterResponse>({
  method: "POST", path: "/api/search", payload
});

const importer = () => handler<{}>({
  method: "POST", path: "/api/import", payload: null
});

const importFilters = () => handler<Filters>( {
  method: 'GET', path: '/api/filters', payload: null
});

export default {
  fetchFilter,
  importer,
  apiEndpoint,
  importFilters
};
