import Button, { ButtonProps } from "@mui/material/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

interface CourseOptionButtonProps extends ButtonProps {
  text: string,
  to: string,
  description: string,
}

const CourseOptionButton: React.FC<CourseOptionButtonProps> = (props) => {
  return (
    <Button
      component={Link}
      variant="contained"
      to={props.to}
      color="enlightTeal"
      fullWidth
      sx={{py: 3, display: 'block', textAlign: "center"}}
    >
      <Typography color="white" variant="h5" fontWeight={700}>
        {props.text}
      </Typography>
      <Typography color="white">
        {props.description}
      </Typography>
    </Button>
  );
};

export default CourseOptionButton;