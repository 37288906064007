import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import {
  Sorting,
  //getComparator,
  sortableFields,
  //stableSort,
} from "../../helpers/sorting";
import { Offering } from "../../@types/courseTypes";

import CourseTableRow from "../list-component/CourseTableRow";
import { Filters } from "../../@types/apiTypes";
import { LoadingRow } from "./LoadingRow";
import { THead } from "./TableHead";
import { TablePaginator } from "./TablePaginator";
import { TableValues } from "../NewContentWrapper";
import { messageRow } from "./MessageRow";
import { useFavorites } from "../../config/useFavorites";



interface TableProps {
  rows: Offering[];
  totalResults: number;
  hasError?: string | null;
  isLoading: boolean;
  showFav?: boolean;
  tableInfo: TableValues;
  updateTable: React.Dispatch<React.SetStateAction<TableValues>>;
  filterOptions: Filters | undefined;
  disclaimer: string | undefined;
}

type FavTableInfo = {
  currentFavPage: number,
  rowsPerFavPage: number
}

/**
 * TableComponent contains table header, table body with rows and paginator component.
 * It controls sorting and expanding the rows as well as calling the use Favorites hook.
 */
export default function TableComponent(props: TableProps) {
  const { rows, hasError, isLoading, tableInfo: { rowsPerPage, currentPage } } = props;

  const [sorting, setSorting] = React.useState<Sorting>({ order: "asc", orderBy: undefined });
  const [expandedRows, setExpandedRows] = React.useState<boolean[]>( Array(rowsPerPage).fill(false) );
  //const [sortedData, setSortedData] = React.useState<Offering[]>( stableSort(rows ?? [], getComparator(sorting)) );

  const [favTableInfo, setFavTableInfo] = React.useState<FavTableInfo>({currentFavPage: 1, rowsPerFavPage: 10});

  const { favItems, addFavItem, removeFavItem } = useFavorites();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const message = props.disclaimer
    ? props.disclaimer
    : !props.isLoading && rows.length < 1
      ? "No result"
      : hasError;

  const favItemsOnCurrentPage = () => favItems.slice((favTableInfo.currentFavPage - 1) * favTableInfo.rowsPerFavPage,
                                            favTableInfo.currentFavPage * favTableInfo.rowsPerFavPage);

  const [currentActiveList, setCurrentActiveList] = React.useState<Offering[]>(props.showFav
    ? favItemsOnCurrentPage
    : rows);

  React.useEffect(() => {
    setCurrentActiveList(props.showFav ? favItemsOnCurrentPage : rows);
  }, [props.showFav, favTableInfo, currentPage, rowsPerPage, sorting, rows]);

  const handleFavAdd = (item: Offering) => {
    const newList = [ ...favItems, item ];
    const findItem = favItems.find( (course) => course.offeringId === item.offeringId );

    if ( findItem ) {
      removeFavItem(item.offeringId);
    } else {
      addFavItem(newList);
    }
  };

  const isFav = (item: Offering): boolean => {
    const findItem = favItems.find( (course) => course.offeringId === item.offeringId );
    return Boolean(findItem);
  };


  const handleRequestSort = (property: keyof typeof sortableFields) => {
    const isAsc = sorting.orderBy === property && sorting.order === "asc";
    setSorting({ order: isAsc ? "desc" : "asc", orderBy: property });
  };


  React.useEffect(() => {
    if (sorting.orderBy !== undefined) {
      props.updateTable({...props.tableInfo, sort:sorting, currentPage: 1});
    }
  }, [sorting]);

  React.useEffect(() => {
    setExpandedRows( Array(rowsPerPage).fill(false) );
  }, [currentPage, rowsPerPage, sorting,]);

  return (
    <Box sx={{ width: "100%" }}>

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium" sx={{borderCollapse: "separate"}}>
          <THead
            order={sorting.order}
            orderBy={sorting.orderBy}
            onRequestSort={handleRequestSort}
            isSmall={isSmallScreen}
            fav={props.showFav}
          />
          <TableBody>
            {
              isLoading
                ?
                  <LoadingRow />
                :
                  rows.length > 0 ? (
                    currentActiveList.map((elem, index) => (
                        <CourseTableRow
                          courseItem={elem}
                          expanded={expandedRows[index]}
                          isSmall={isSmallScreen}
                          setExpanded={(expand) => {
                            setExpandedRows(
                              expandedRows.map((v, i) => (i === index ? expand : v))
                            );
                          }}
                          key={elem.offeringId}
                          isFavored={ isFav(elem) }
                          handleFavorite={handleFavAdd}
                          filterOptions={props.filterOptions}
                        />
                      ))
                  ) : ( message && message !== "" && messageRow({text: message}) )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {!props.showFav ?
        <TablePaginator
          totalResults={props.tableInfo.totalRows}
          currentPage={props.tableInfo.currentPage}
          noOfRows={props.tableInfo.rowsPerPage}
          setNewPage={(v) => props.updateTable({...props.tableInfo, currentPage: v})}
          setPageLength={(v) => props.updateTable({...props.tableInfo, currentPage: 1, rowsPerPage: v})}
        />
        :
        <TablePaginator
          totalResults={favItems.length}
          currentPage={favTableInfo.currentFavPage}
          noOfRows={favTableInfo.rowsPerFavPage}
          setNewPage={(v) => setFavTableInfo({...favTableInfo, currentFavPage: v})}
          setPageLength={(v) => {setFavTableInfo({...favTableInfo, currentFavPage: 1, rowsPerFavPage: v});}}
        />
      }

    </Box>
  );
}
