import * as React from 'react';

import Container from '@mui/material/Container';
import CourseOptionButton from '../components/CourseOptionButton';
import SectionHead from '../components/SectionHead';
import Stack from '@mui/material/Stack';
import WrapperComponent from '../components/WrapperComponent';



const containerStyles = {
  mt: 0,
  width: "100%",
};


const ApplicationProcessPage = () => {
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={containerStyles}
    >
      <SectionHead text="Currently in ENLIGHT you have three main options for taking courses abroad" align="center" />
      <WrapperComponent>
        <Stack spacing={2} sx={{ pb: 8, mx: {xs: 1, md: 2}}}>
          <CourseOptionButton text="Semester Abroad" description='Study as an exchange student in a partner university' to="abroad" />
          <CourseOptionButton text="Fully Online Courses" description='Study in international classroom without leaving home' to="online" />
          <CourseOptionButton text="Short Term Blended Courses" description='Choose a combined online and on-campus course' to="blended" />
        </Stack>
      </WrapperComponent>
    </Container>
  );
};

export default ApplicationProcessPage;
