import * as React from "react";

import { Container, ContainerProps } from "@mui/material";

interface WrapperComponentProps extends ContainerProps {}

/**
 * Wrapper component for setting maximal layout width, used in Footer and Navbar components
 */
const WrapperComponent: React.FC<WrapperComponentProps> = (props) => {
  return (
    <Container maxWidth="xl" {...props} disableGutters />
  );
};

export default WrapperComponent;
