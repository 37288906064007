import * as React from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";



const itemsPerPageCount = [10, 25, 50, 100];

interface TablePaginatorProps {
  totalResults: number;
  currentPage: number;
  expandedRowIds?: number;
  noOfRows: number;
  setPageLength: (pageLength:number) => void;
  setNewPage: (pageNumber:number) => void;
}
/**
 * Paginator displays options to change the number of rows in the table, shows the rows visible out of the total (e.g., 1-10 of 130),
 * and provides table navigation links.
 */
export const TablePaginator: React.FC<TablePaginatorProps> = (props) => {

  const numberOfPages = Math.ceil( props.totalResults ? props.totalResults / props.noOfRows : 0 );

  return (
    <Toolbar sx={{flexDirection: {xs: "column", sm: "row"}, justifyContent: "right", alignItems: {xs: "flex-end", sm: "center"}, fontSize: "0.875rem", gap: 1 }}>
      <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
        <InputLabel id="rowNumberLabel" sx={{ lineHeight: "1.5rem", color: "rgba(0, 0, 0, 0.87)",  display: {xs: "none", md: "block"} }}>
          Rows per page:
        </InputLabel>

        <InputLabel id="rowNumberLabel" sx={{ lineHeight: "1.5rem", color: "rgba(0, 0, 0, 0.87)", display: {xs: "block", md: "none"} }}>
          Rows:
        </InputLabel>

        <Select
          variant="standard"
          disableUnderline
          labelId="rowNumberLabel"
          value={props.noOfRows}
          onChange={({target,currentTarget}) => props.setPageLength( (target ||currentTarget).value as number )}
          sx={{margin: "4px 16px", "& .MuiInputBase-input": {margin: "0 0 -2px 0"}}}
        >
          {
            itemsPerPageCount.map( (pageSize, idx) =>
              <MenuItem value={pageSize} key={idx}>
                {pageSize}
              </MenuItem>
            )
          }
        </Select>
        <Typography>
          {(props.currentPage - 1) * props.noOfRows + 1}-{(props.currentPage * props.noOfRows < props.totalResults) ? props.currentPage * props.noOfRows : props.totalResults} of {props.totalResults ?? 0}
        </Typography>
      </Box>

      <Box sx={{display: "flex", alignItems: "center"}}>

        <Pagination
          size="small"
          siblingCount={1}
          boundaryCount={1}
          count={numberOfPages}
          page={props.currentPage}
          onChange={(evt, newPage) => props.setNewPage(newPage)}
          sx={{"& .MuiButtonBase-root": {fontSize: "0.875rem"}}}
        />
      </Box>

    </Toolbar>
  );
};
