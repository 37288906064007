import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

interface MessageRowProps {
  text: string;
}

/** Shows 'No results' or error message to the user */
export const messageRow: React.FC<MessageRowProps> = ({ text }) => (
  <TableRow>
    <TableCell colSpan={6}>
      <Typography variant="h5">
        { text }
      </Typography>
    </TableCell>
  </TableRow>
);
