import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

import EUlogos from "../../assets/images/newLogos/EUlogos.png";


const contentsStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "secondary.light"
};


const EUlogo = styled("img")({
  maxHeight: "3rem",
  maxWidth: "100%"
});

const Content: React.FC = () => {
  return (
    <Grid container component="div" spacing={3} sx={contentsStyles}>
      <Grid item xs={12} md={6} lg={7} xl={8} sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", gap: 1}}>
        <Typography variant="subtitle1">
          The content of this website represents the views of the authors only and is their sole responsibility.
        </Typography>
        <Typography variant="subtitle1">
          The European Commission and the Agency do not accept any responsibility for any use of the information it contains.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={5} xl={4} sx={{textAlign: {xs: "left", md: "right"}}}>
        <EUlogo src={EUlogos} />
      </Grid>
    </Grid>
  );
};

export default Content;
