import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import { DatePicker, MultiSelect, Search, Slider } from "../fields";
import { FilterFormProps, Filters } from "../../@types/apiTypes";
import { initialValues } from "../NewContentWrapper";
import { styles } from "../fields/styles";


const lightBlueColor = "rgba(51, 188, 220, 0.1)";


interface FilterComponentProps {
  filterState: FilterFormProps;
  updateValues: (values: FilterFormProps) => void;
  isLoading?: boolean;
  filterOptions: Filters | undefined;
}

/**
 * Filter component for finding courses based on search term, host university, language,
 * format, level, start and end dates as well as ECTS value
 */
export const FilterComponent: React.FC<FilterComponentProps> = (props) => {

  const [form, setForm] = React.useState<FilterFormProps>(props.filterState);


  const handleFieldUpdate = (fieldName: keyof FilterFormProps, fieldValue: any) => {
    return setForm({ ...form , [fieldName]: fieldValue });
  };


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if ( form === props.filterState ) {
      return;
    } else {
      props.updateValues( form );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={ (e) => handleSubmit(e) }
      sx={{
        backgroundColor: lightBlueColor,
        py: 2,
        px: {xs: "1rem", sm: 3}
      }}
    >

      <Grid container spacing={2} >

        {/* First full row item, 12 column based layout. xs={12} means that starting
        from smallest size, the item takes up 12 columns */}
        <Grid item xs={12}>
          <FormControl sx={{width: "100%"}}>
            <Search
              id="search"
              name="q"
              value={form.q}
              onChange={(e) => handleFieldUpdate( "q", (e.currentTarget ?? e.target).value )}
            />
          </FormControl>
        </Grid>


        {/* Second row, 7 inputs */}
        <Grid container item spacing={2} xs={12}>

          <Grid item xs={12} sm={6} md={3} lg>
            <MultiSelect
              name="university"
              label="Hosts"
              value={ form.university }
              options={ props.filterOptions?.universities }
              cb={ handleFieldUpdate }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <MultiSelect
              name="language"
              label="Languages"
              value={form.language}
              options={props.filterOptions?.languages}
              cb={ handleFieldUpdate }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <MultiSelect
              name="modeOfDelivery"
              label="Formats"
              value={ form.modeOfDelivery }
              options={ props.filterOptions?.formats }
              cb={ handleFieldUpdate }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <MultiSelect
              name="level"
              label="Level"
              value={ form.level }
              options={ props.filterOptions?.level }
              cb={ handleFieldUpdate }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <DatePicker
              name="from"
              label="Select start date"
              value={form.from}
              cb={handleFieldUpdate}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg>
            <DatePicker
              name="to"
              label="Select end date"
              value={ form.to }
              cb={ handleFieldUpdate }
            />
          </Grid>

          <Grid item xs={12} md={6} lg>
            <Slider
              name="ectsMin"
              value={[form.ectsMin, form.ectsMax]}
              cb={ handleFieldUpdate }
            />
          </Grid>

        </Grid>

        {/* Third row: buttons */}
        <Grid container item xs={12}
          sx={{
            flexDirection: {xs: "column", lg: "row"},
            alignSelf: "center",
            textAlign: "center",
            justifyContent: "end",
            alignItems: "center",
          }}>

          <Grid item xs={12} md={1.5}
            component={Button}
            type="submit"
            variant="contained"
            disabled={props.isLoading}
            sx={{...styles.submitButtonStyles, order: {xs: 1, lg: 2}}}
          >
            { props.isLoading ? <CircularProgress sx={{display:'inline'}} /> : "Filter" }
          </Grid>

          <Grid item
            md="auto"
            component={Button}
            type="reset"
            onClick={()=> setForm(initialValues)}
            sx={{ fontSize: '.8rem', color: "enlightTeal.main", order: {xs: 2, lg: 1} }}
          >
            Reset filter
          </Grid>

        </Grid>
      </Grid>

    </Box>
  );
};
