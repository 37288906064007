import { createTheme } from "@mui/material/styles";




export const enlightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    enlightTeal: {
      // enlight teal
      main: "rgba(23, 72, 106, 1)",
      light: "rgba(23, 72, 106, 0.5)",
      lighter: "rgba(23, 72, 106, 0.3)"
    },
    warning: {
      // e.g. red favorites button
      main: "rgba(181, 50, 54, 1)",
    },
    info: {
      // e.g. filter container background
      main: "rgba(51, 188, 220, 0.1)",
    },
    secondary: {
      // e.g. table header
      main: "rgba(242, 247, 250, 1)",
      light: "rgba(242, 247, 250, 0.5)"
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.36)",
      disabledBackground: "rgba(0, 0, 0, 0.2)",
    },
    whiteWithOpacity: {
      main: "rgba(255,255,255,.25)"
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: "4.5rem",
    },
    h2: {
      fontWeight: 300,
      fontSize: "2.5rem",
      lineHeight: "4rem"
    },
    h3: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: "3rem",
    },
    h4: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem"
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem"
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: "1.5rem"
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.5rem"
    },
    subtitle1: {
      // "details" in figma
      fontSize: "0.75rem",
      lineHeight: "1.25rem"
    },
    button: {
      textTransform: "none"
    }
  },
});


declare module '@mui/material/styles' {
  interface Palette {
    whiteWithOpacity: Palette['primary'];
    enlightTeal: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    whiteWithOpacity?: PaletteOptions['primary'];
    enlightTeal?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    whiteWithOpacity: true;
    enlightTeal: true;
  }
}


