import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { Order, sortableFields } from "../../helpers/sorting";

interface THeadProps {
  onRequestSort: (property: keyof typeof sortableFields) => void;
  order: Order;
  orderBy: string | undefined;
  isSmall: boolean;
  fav: boolean | undefined;
}

const tableHeadStyles = {
  backgroundColor: "#F2F7FA"
};

const tableCellStyles = {
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
  color: "rgba(23, 72, 106, 1)",
  paddingX: "0.5rem",
  paddingTop: "1.5rem",
  paddingBottom: "1.5rem",
  "& .MuiButtonBase-root": {
    color: "rgba(23, 72, 106, 1)"
  }
};

/**
 * Table head containing the column names - Course name, ECTS, University, Date start/end, Favorites -
 * and labels for sorting the rows in the page according to the values in the column
 */
export const THead: React.FC<THeadProps> = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof typeof sortableFields) => () => {
    onRequestSort(property);};

  return (
    <TableHead sx={tableHeadStyles}>
      <TableRow>
        {props.isSmall ?
            <TableCell sx={{...tableCellStyles, paddingX: "1.5rem", display: "flex", justifyContent: "space-between" }} >
              <Typography fontWeight={700} fontSize={"1rem"}>Courses</Typography>
              <Typography fontSize={"1rem"}>Favourites*</Typography>
            </TableCell>
          : <>
              <TableCell
                key="name"
                align="left"
                padding="normal"
                colSpan={2}
                sortDirection={orderBy === "name" ? order : false}
                sx={{ ...tableCellStyles, pl: "24px" }}
              >
                { props.fav
                  ? "Course name"
                  : <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={createSortHandler("name")}
                  >
                    {"Course name"}
                    {orderBy === "name" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>

              <TableCell
                key="ects"
                align="left"
                padding="normal"
                sortDirection={orderBy === "ects" ? order : false}
                sx={{ ...tableCellStyles, width: "6%" }}
              >
                { props.fav
                  ? "ECTS"
                  : <TableSortLabel
                    active={orderBy === "ects"}
                    direction={orderBy === "ects" ? order : "asc"}
                    onClick={createSortHandler("ects")}
                  >
                    {"ECTS"}
                    {orderBy === "ects" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>

              <TableCell
                key="university"
                align="left"
                padding="normal"
                sortDirection={orderBy === "university" ? order : false}
                sx={{ ...tableCellStyles, width: "18%" }}
              >
                { props.fav
                  ? "University"
                  : <TableSortLabel
                    active={orderBy === "university"}
                    direction={orderBy === "university" ? order : "asc"}
                    onClick={createSortHandler("university")}
                  >
                    {"University"}
                    {orderBy === "university" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>
              <TableCell
                key="startDate"
                align="left"
                padding="normal"
                sortDirection={orderBy === "startDate" ? order : false}
                sx={{ ...tableCellStyles, width: "16%" }}
              >
                { props.fav
                  ? "Date start/end"
                  : <TableSortLabel
                      active={orderBy === "startDate"}
                      direction={orderBy === "startDate" ? order : "asc"}
                      onClick={createSortHandler("startDate")}
                    >
                      {"Date start/end"}
                      {orderBy === "startDate" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                }
              </TableCell>

              <TableCell sx={{ ...tableCellStyles, width: "6%", pr: "24px" }}>
                {"Favorites"}
              </TableCell>
            </>
        }
      </TableRow>
    </TableHead>
  );
};
