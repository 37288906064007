import Box from "@mui/material/Box";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

import WrapperComponent from "./WrapperComponent";

const StyledBox = styled(Box)(() => ({
  borderRadius: "1rem",
  minHeight: "fit-content",
  textAlign: "center",
  padding: "1rem",
}));

const StyledArrow = styled(DoubleArrowIcon)(({theme}) => ({
  alignSelf: "center",
  color: theme.palette.enlightTeal.main
}));



const StepDiagram: React.FC<{variant: string}> = ({variant}) => {

  return (
    <Box sx={{ px: {xs: "1rem", md: "4rem"}}}>
      <WrapperComponent sx={{margin: "auto"}}>
        <Stack
          alignItems="stretch"
          mb={4}
          width="100%"
          sx={{flexDirection: {xs: "column", md: "row"}}}
        >
          <StyledBox sx={{backgroundColor: "#96C6D7", width: {xs: "100%", md: '20%'}, flexGrow: 1}}>
            <Typography variant="h4" fontWeight={700} color="white">
              Select
            </Typography>
            <Typography color="white" lineHeight="1.2rem">
              {variant==='blended' ? 'a course you would like to study' : 'courses you would like to study'}
            </Typography>
          </StyledBox>

          <StyledArrow sx={{ transform: {xs: "rotate(90deg)", md: "none"}}} />

          <StyledBox sx={{backgroundColor: "#76A7BC", width: {xs: "100%", md: "20%"}, flexGrow: 1}}>
            <Typography variant="h4" fontWeight={700} color="white">
              Check
            </Typography>
            <Typography color="white" lineHeight="1.2rem">
              {variant === 'blended'
                ? 'your study adviser whether the selected course fits for your programme   '
                : 'your study adviser whether the selected courses fit for your programme'}
            </Typography>
          </StyledBox>

          <StyledArrow sx={{ transform: {xs: "rotate(90deg)", md: "none"}}} />

          <StyledBox sx={{backgroundColor: "#5787A1", width: {xs: "100%", md: "20%"}, flexGrow: 1}}>
            <Typography variant="h4" fontWeight={700} color="white">
              Find out
            </Typography>
            <Typography color="white" lineHeight="1.2rem">
              {variant === 'abroad' && 'when you can apply for study abroad'}
              {variant === 'online' && 'how you can sign up for the courses at a host university'}
              {variant === 'blended' && 'when you can apply for short-term blended mobility '}
            </Typography>
          </StyledBox>


          {variant !== 'online' &&
            <>
              <StyledArrow sx={{ transform: {xs: "rotate(90deg)", md: "none"}}} />
              <StyledBox sx={{backgroundColor: "#376885", width: {xs: "100%", md: "20%"},}}>
                <Typography variant="h4" fontWeight={700} color="white">
                  Apply
                </Typography>
                <Typography color="white" lineHeight="1.2rem">
                  {variant === 'blended' ? 'for the course you have selected' : 'for semester studies abroad'}
                </Typography>
              </StyledBox>

              <StyledArrow sx={{ transform: {xs: "rotate(90deg)", md: "none"}}} />

              <StyledBox sx={{backgroundColor: "enlightTeal.main", width: {xs: "100%", md: "20%"},}}>
                <Typography variant="h4" fontWeight={700} color="white">
                  Follow
                </Typography>
                <Typography color="white" lineHeight="1.2rem">
                  instructions to get nominated and accepted to host university
                </Typography>
              </StyledBox>
            </>
          }

        </Stack>
      </WrapperComponent>
    </Box>
  );
};

export default StepDiagram;