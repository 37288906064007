import Link, { LinkProps } from "@mui/material/Link";


const ExternalLink: React.FC<LinkProps> = (props) => {
  return (
    <Link
      target="_blank"
      rel="nofollow"
      {...props}
    />
  );
};

export default ExternalLink;
