import { SxProps, Theme } from "@mui/material/styles";


const styles = {
  select: {
    minWidth: "100%",
    height: "2rem",
    backgroundColor: "white",
    textOverflow: "clip",
    "& .MuiOutlinedInput-input": {
      textOverflow: "clip",
    },
  },

  selectLabel: {
    transform: "none",
    position: "relative",
    color: "#17486A",
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    paddingBottom: "0.25rem"
  },

  selectChip: {
    color: "#17486A",
    fontSize: "0.75rem",
    lineHeight: '1.125rem',
    backgroundColor: 'transparent',
    border: "1px solid rgba(23, 72, 106, 0.3)",
    borderRadius: 1,
  },

  datePickerLabel: {
    transform: "none",
    position: "relative",
    color: "#17486A",
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    paddingBottom: "0.25rem"
  },

  submitButtonStyles: {
    textTransform: "none",
    fontWeight: 700,
    lineHeight: "1.25rem",
    fontSize: "0.75rem",
    letterSpacing: "0.04em",
    height: "28px",
    minWidth: {xs: "100%", lg: "10%"},
    border: "1px solid rgba(23, 72, 106, 0.3)",
    backgroundColor: "enlightTeal.main"
  }

} as {
  [ key:string ]: SxProps<Theme> | React.CSSProperties;
};

export { styles };
