import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


import { FilterComponent } from "./searchContainer/FilterComponent";
import TableComponent from "./newTable/Table";

import Api from "../config/api";

import { FilterFormProps, Filters, Payload } from "../@types/apiTypes";
import { Offering } from "../@types/courseTypes";
import { Sorting } from "../helpers/sorting";
import { TableTitle } from "./newTable/TableTitle";


export const initialValues = {
  university: [],
  q: "",
  modeOfDelivery: [],
  language: [],
  from: "",
  to: "",
  level: [],
  ectsMin: 0,
  ectsMax: 30
} as FilterFormProps;

export type TableValues = {
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  sort?: Sorting;
}
const initialTable = {
  currentPage: 1,
  rowsPerPage: 10,
  totalRows: 0
};

interface ContentWrapperProps {
  fav?: boolean;
}

/**
 * MainContentWrapper is a wrapper component which provides a user interface for
 * displaying and interacting with a table of courses and filtering options.
 * @param props: fav (optional) - A boolean prop indicating whether to display
 * the favorite courses and hide filter component. Defaults to false.
 */
const MainContentWrapper: React.FC<ContentWrapperProps> = (props) => {
  const [filterState, setFilterState] = React.useState<FilterFormProps>(initialValues);
  const [tableConfig, setTableConfig] = React.useState<TableValues>(initialTable);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string|null>(null);
  const [responseItems, setResponseItems] = React.useState<Offering[]>([]);

  const [showFavorites, setShowFavorites] = React.useState<boolean>(props.fav ?? false);
  const [filterOptions, setFilterOptions] = React.useState<Filters>();
  const [disclaimerMessage, setDisclaimerMessage] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setTableConfig((prevTableConfigState) => {
      return {...prevTableConfigState, currentPage: 1};
    });
  }, [filterState]);

  React.useEffect(() => {
    Api.importFilters()
      .then((filters) => {
        if (filters) {
          setFilterOptions(filters);
          setFilterState((prev) => ({...prev, ectsMax: filters.maxECT}));
        }
      })
      .catch( (err: Error) => {
        setErrorMessage(err.message);
      });
    }, []);


  React.useEffect(() => {
  /* set loading true to render loader and give
      ux feedback background loading is happening */
    setLoading(true);

    let payload: Payload = {
      ...filterState,
      pageSize: tableConfig.rowsPerPage,
      pageNumber: tableConfig.currentPage
    };

    if (tableConfig.sort) {
      payload['sort'] = [`${tableConfig.sort.order === 'asc'? '' : '-' }${tableConfig.sort.orderBy}`];
    };

    Api.fetchFilter(payload)
    .then( (data) => {
      if (data) {
        setDisclaimerMessage(undefined);
        setTableConfig((prevTableConfigState) => {
          return {...prevTableConfigState, totalRows: data.totalResults};
        });
        setResponseItems(data.items);
        if (data.totalResults === 0
          && filterState.university.length === 1
          && filterState.q === ""
          && filterState.modeOfDelivery.length === 0
          && filterState.language.length === 0
          && filterState.from === ""
          && filterState.to === ""
          && filterState.level.length === 0
          && filterState.ectsMin === initialValues.ectsMin
          && filterState.ectsMax === initialValues.ectsMax
        ) {
          setDisclaimerMessage("No results found, this university doesn't seem to have any data in Enlight course catalogue yet");
        }
      }
    })
    .catch( (err: Error) => {
      setErrorMessage(err.message);
    })
    .finally(() => setLoading(false));
  }, [ filterState, tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.sort ]);


  return (
    <Stack spacing={2} maxWidth="100%" mb={4}>
      <TableTitle
        showFavorite={showFavorites}
        setShowFavorites={setShowFavorites}
      />

      {!showFavorites &&
        <FilterComponent
          isLoading={loading}
          filterState={filterState}
          updateValues={setFilterState}
          filterOptions={filterOptions}
        />
      }

      <TableComponent
        rows={ responseItems }
        isLoading={loading}
        totalResults={tableConfig.totalRows}
        hasError={errorMessage}
        tableInfo={tableConfig}
        updateTable={setTableConfig}
        showFav={showFavorites}
        filterOptions={filterOptions}
        disclaimer={!showFavorites ? disclaimerMessage : undefined}
      />

      <Button
        component={RouterLink}
        to="/application-process"
        variant="contained"
        color="enlightTeal"
        sx={{ maxWidth: 'sm', margin: 'auto', alignSelf: 'center', p: 2 }}
      >
        <Typography color="white" fontWeight={700}>
          See about application process
        </Typography>
      </Button>

    </Stack>
  );
};

export default MainContentWrapper;
