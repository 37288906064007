import * as React from "react";
import { DateTime } from "luxon";

import { FormControl, TextField, TextFieldProps } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

import { FieldProps } from ".";
import { styles } from "./styles";

import { BackendAcceptedDateFormat } from "../../@types/filterTypes";


export const DATE_INPUT_FORMAT = "dd/MM/yyyy";
export const API_DATE_FORMAT = "yyyy-MM-dd";

interface DatePickerFieldProps {
  label: string;
}

type DPP = FieldProps<BackendAcceptedDateFormat> & DatePickerFieldProps;

export const DatePicker: React.FC<DPP> = (props) => {


  const handleChange = (value: DateTime | string | null) => {
    if ( value ) {
      const newValue = typeof value === "string"
        ? value
        : value.toFormat(API_DATE_FORMAT);

      if ( props.cb ) {
        props.cb( props.name, newValue );
      }
    } else {
      if (props.cb) {
        props.cb( props.name, "" );
      }
    }
  };

  const renderInput =(params : TextFieldProps) => (
    <TextField
      fullWidth
      label={props.label}
      InputLabelProps={{
        sx: styles.datePickerLabel,
        shrink: false
      }}
      sx={{"& .MuiInputBase-root": { minWidth: "100%", height: "2rem", backgroundColor: "white", }}}
      placeholder={DATE_INPUT_FORMAT}
      {...params}
    />
  );

  return (
    <FormControl fullWidth>
      <MuiDatePicker
        showDaysOutsideCurrentMonth
        inputFormat={DATE_INPUT_FORMAT}
        mask="__/__/____"
        onChange={ handleChange }
        renderInput={ renderInput }
        {...props}
        value={props.value.length > 0 ? props.value : null}
      />
    </FormControl>
  );
};
