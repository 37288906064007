import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { universities, universityLinks } from "../settings";
import ExternalLink from "./ExternalLink";
import UniSelectionButton from "./UniSelectionButton";
import { UniversityLinks } from "../@types/filterTypes";
import WrapperComponent from "./WrapperComponent";

enum Variant {
  Abroad = 'abroad',
  Blended = 'blended',
}


const UniDropdownContainer: React.FC<{variant: string}> = (props) => {
  const [currentUni, setCurrentUni] = React.useState<keyof UniversityLinks[Variant]>('');
  const [targetUni, setTargetUni] = React.useState<keyof UniversityLinks[Variant]>('');
  const variant: Variant = props.variant as Variant;

  const currentCb = (event: React.MouseEvent<HTMLLIElement>) => {
    setCurrentUni(event.currentTarget.outerText);
  };

  const targetCb = (event: React.MouseEvent<HTMLLIElement>) => {
    setTargetUni(event.currentTarget.outerText);
  };

  const currentUniversitiesWithLinks = universities.filter((university) => universityLinks[variant][university.label]?.outgoing);
  const incomingUniversitiesWithLinks = universities.filter((university) => universityLinks[variant][university.label]?.incoming);


  return (
    <>
      <Box sx={{
        backgroundColor: "rgba(235, 248, 252, 1)",
        pt: 2,
        pb: 4,
        mb: 4,
        px: {xs: "1rem", md: "4rem"}
      }}>
        <WrapperComponent sx={{margin: "auto"}}>
          <Stack direction={{xs: "column", md: "row"}} spacing={2}>

            <UniSelectionButton
              text="Pick your university to see what internal steps you need to take"
              cb={currentCb}
              universities={currentUniversitiesWithLinks}
            />

            <UniSelectionButton
              text="Pick the university you plan to go to and see what internal steps you need to take"
              cb={targetCb}
              universities={incomingUniversitiesWithLinks}
            />

          </Stack>

        </WrapperComponent>
      </Box>
      <Box sx={{px: {xs: "1rem", md: "4rem"}}}>
        <WrapperComponent sx={{ margin: 'auto', mb: 4, }}>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Box width='100%'>
              { universityLinks[variant][currentUni] && <Typography>{currentUni}:</Typography>}
              <ul>
                { universityLinks[variant][currentUni]?.outgoing?.eng &&
                  <li>
                    <ExternalLink href={universityLinks[variant][currentUni]?.outgoing?.eng}>
                      {universityLinks[variant][currentUni]?.outgoing?.eng}
                    </ExternalLink>
                  </li>
                }
                { universityLinks[variant][currentUni]?.outgoing?.native &&
                  <li>
                    <ExternalLink href={universityLinks[variant][currentUni]?.outgoing?.native}>
                      {universityLinks[variant][currentUni]?.outgoing?.native}
                    </ExternalLink>
                  </li>
                }
              </ul>
            </Box>
            <Box width='100%'>
              {universityLinks[variant][targetUni] && <Typography>{targetUni}:</Typography>}
              <ul>
                { universityLinks[variant][targetUni]?.incoming?.eng &&
                  <li>
                    <ExternalLink href={universityLinks[variant][targetUni]?.incoming?.eng}>
                      {universityLinks[variant][targetUni]?.incoming?.eng}
                    </ExternalLink>
                  </li>
                }
                { universityLinks[variant][targetUni]?.incoming?.native &&
                  <li>
                    <ExternalLink href={universityLinks[variant][targetUni]?.incoming?.eng}>
                      {universityLinks[variant][targetUni]?.incoming?.native}
                    </ExternalLink>
                  </li>
                }
              </ul>
            </Box>
          </Stack>
        </WrapperComponent>
      </Box>
    </>
  );
};

export default UniDropdownContainer;