import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

import Content from "./Content";
import Links from "./Links";
import Logos from "./Logos";
import WrapperComponent from "../WrapperComponent";

const footerStyles = {
  padding: { xs: "1rem", md: "1.5rem 4rem"},
  backgroundColor: "enlightTeal.main"
};

const StyledHr = styled("hr") (({theme}) => ({
  width: "100%",
  color: theme.palette.secondary.light,
}));

const Footer: React.FC = () => {

  return (
    <Box component="footer" sx={footerStyles}>
      <WrapperComponent sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        textAlign: "center",
      }}>
        <Logos />
        <StyledHr />
        <Content />
        <StyledHr />
        <Links />
      </WrapperComponent>
    </Box>
  );
};

export default Footer;
