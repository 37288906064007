import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Loader from "../list-component/Loader";


export const LoadingRow: React.FC = () => (
  <TableRow>
    <TableCell colSpan={6}>
      <Loader />
    </TableCell>
  </TableRow>
);
