import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExternalLink from '../components/ExternalLink';
import SectionHead from '../components/SectionHead';
import StepDiagram from '../components/StepDiagram';
import UniDropdownContainer from '../components/UniDropdownContainer';
import WrapperComponent from '../components/WrapperComponent';



const containerStyles = {
  mt: 0,
  width: "100%",
};

const textContent = {
  abroad: {
    text: "Semester Abroad",
    description: <Typography color="enlightTeal.main" sx={{ whiteSpace: 'pre-line' }}>Studying abroad for a semester or two is the most common and classical way of getting international learning experience. It means leaving your home country and home university and spending that time studying in a partner (host) university as an exchange student. Most of the semester abroad studies take place within the Eramus+ programme.
  Within the ENLIGHT network, the students can apply for semester abroad studies to partner universities. The process differs across universities as how and when the relevant study abroad calls are announced. However, the steps students need to take to spend a semester at any ENLIGHT university have some similarities and these are briefly described below.</Typography>,
    overall: `Admission notification by your host university proves that process is successfully completed.
  As it is important to know the application process at your home university and expectations of the host university, use the drop-down menu to learn the precise procedure. `},
  blended: {
    text: "Short Term Blended Courses",
    description: <Typography color="enlightTeal.main" sx={{ whiteSpace: 'pre-line' }}>Blended mobility is a short-term physical mobility combined with a virtual activity. ENLIGHT universities offer blended courses and there are two main application periods:
  <ul><li>For the courses taking place from June to December, students can apply in April.</li>
  <li>For the courses taking place from January to May, students can apply in November.</li></ul>
  Most of the blended mobility courses are delivered within Erasmus+ Program and there are grants available to finance your physical mobility. The application process differs, so please check info on blended courses delivered jointly by several universities <ExternalLink href='https://enlight-eu.org/students/courses'>here</ExternalLink>. </Typography>},
  online: {
    text: "Fully Online Courses",
    description: <Typography color="enlightTeal.main" sx={{ whiteSpace: 'pre-line' }}>Fully online courses are offered in a completely online environment, no in person or on campus meetings. Generally, there is no need to apply for study abroad and there is no financial support for attending online courses as these take place parallel to students’ studies at their home university. For attending an online course in another ENLIGHT university, students need to follow the instructions described for a particular course, please check online courses also from <ExternalLink href='https://enlight-eu.org/students/courses'>this website</ExternalLink>.</Typography>},
};


const CourseOptionPage: React.FC<{variant: string}> = (props) => {
  const texts = props.variant === 'abroad'
    ? textContent.abroad
    : props.variant === 'blended'
      ? textContent.blended
      : textContent.online;


  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={containerStyles}
    >
      <SectionHead text={texts.text} align="left">{texts.description}</SectionHead>
      <StepDiagram variant={props.variant} />
      {props.variant === 'abroad' && <SectionHead align="left">{props.variant === 'abroad' && textContent.abroad.overall}</SectionHead>}
      {/* <Box sx={{ px: {xs: "1rem", md: "4rem"}}}>
        <WrapperComponent>
          <Stack spacing={2} sx={{ pb: 8, }}>
            {props.variant === 'abroad' &&
              <Typography sx={{ whiteSpace: 'pre-line'}}>
                {textContent.abroad.overall}
              </Typography>}
          </Stack>
        </WrapperComponent>
      </Box> */}
      {props.variant !== 'online' && <UniDropdownContainer variant={props.variant} />}
    </Container>
  );
};

export default CourseOptionPage;
