import { InputAdornment, InputBase, InputBaseProps, styled } from "@mui/material";

import search from "../../assets/images/icons/searchIcon.png";


const StyledInputBase = styled(InputBase)(() => ({
  backgroundColor: "white",
  borderRadius: "2rem",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  width: "100%",
  height: "2rem",
  lineHeight: "20px",
  fontSize: ".8rem",
  padding: "0 1.25rem",
}));

export const Search: React.FC<InputBaseProps> = (props) => {
  return (
      <StyledInputBase
        placeholder="Search e.g. Sustainable Development..."
        value={props.value}
        onChange={props.onChange}
        startAdornment={
          <InputAdornment position="start">
            {/* <SearchIcon sx={{ width: "16px", height: "16px" }} /> */}
            <img src={search} style={{width: "16px", height: "16px"}} />
          </InputAdornment>
        }
      />
  );
};
